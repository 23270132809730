import React from 'react';
import logo from '../../assets/logo-altumind.png';

const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="flex flex-col sm:flex-row gap-y-[5px] bg-[#E8EEFF] text-sm font-pop pt-10 pb-5 justify-between items-center w-full">
      <p className="flex gap-2 items-center">
        Powered By
        <a href="https://altumindglobal.com/" target='blank'>
          <img src={logo} alt="logo" className="w-[105px]" />
        </a>
      </p>
      <p>© {currentYear} CogniSmiles. All rights reserved.</p>
    </div>
  );
};

export default Copyright;
