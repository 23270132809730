import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import { Suspense } from 'react';
import Loader from './components/CommonComponents/Loader';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
   <HelmetProvider>
      <Router >
          <Suspense fallback={<Loader/>}>
          <Routing />
          </Suspense>
      </Router>
      </HelmetProvider>
  );
}

export default App;
