import React, { createContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/cognismiles-logo.png"

const Navbar = ({ path, selectedOption, setSelectedOption }) => {
  const [menu, setMenu] = useState();
  const [nav, setNav] = useState(false);
  const [show, setShow] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const location = useLocation();

  const fetchData = () => (
    axios
    .get(`${process.env.REACT_APP_BASE}/api/menus?nested&populate=*`)
    .then((json) => {
      setMenu(json?.data?.data[0]?.attributes?.items?.data);
    })
    .catch((error) => {
  
    })
  )

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
   fetchData();
  }, []);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <>
      {/* topbar starts */}
      <div className="flex fixed top-0 w-full z-[99] bg-primary  justify-between py-2 sm:py-1 sm:px-7 px-3 items-center">
        <div className="text-white flex gap-2 items-center">
          {/* <svg
            class="w-4 h-4 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none" 
            viewBox="0 0 17 21"
          >
            <g
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path d="M8 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              <path d="M13.8 12.938h-.01a7 7 0 1 0-11.465.144h-.016l.141.17c.1.128.2.252.3.372L8 20l5.13-6.248c.193-.209.373-.429.54-.66l.13-.154Z" />
            </g>
          </svg>
          <p className="text-[10px] sm:text-base">
            14 Derwent Street Consett, DH8 8LU |{" "}
            Four Lane Ends Benton NE7 7UH
          </p> */}
          <div>
            <select
             name="dropdown"
             value={selectedOption}
             onChange={handleChange}
            className="bg-transparent cursor-pointer border border-white rounded-md p-1 text-sm outline-none focus:outline-none">
            <option value={'Rosebrough Dental Practice'} className="text-black cursor-pointer">Rosebrough Dental Practice </option>
            <option value={'Derwent Street Dental Practice'} className="text-black cursor-pointer">Derwent Street Dental Practice</option>
            </select>
          </div>
        </div>

        <div className="relative"  onClick={() => setShowLocation(!showLocation)} >
          <svg  className="w-6 h-6 text-white md:hidden" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"/>
          </svg>
          {
          showLocation && 
          <div className="md:hidden rounded px-3 py-2 absolute top-8 right-0 bg-white text-black min-w-[250px]">
        <svg class="w-6 h-6 text-white absolute right-0 -top-[11px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M5.575 13.729C4.501 15.033 5.43 17 7.12 17h9.762c1.69 0 2.618-1.967 1.544-3.271l-4.881-5.927a2 2 0 0 0-3.088 0l-4.88 5.927Z" clip-rule="evenodd"/>
        </svg>
          {
            selectedOption === "Rosebrough Dental Practice" &&
            <div> 
            <p className="text-sm ">Rosebrough Dental Practice
              
            </p>
            <p className="font-light text-sm py-1"> Four Lane Ends Benton NE7 7UH  </p>

           
            <p className="text-sm flex item-center gap-1 font-light"> <svg class="w-5 h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"/>
            </svg>01912662095</p>
            </div>
          }
           {
            selectedOption === "Derwent Street Dental Practice" &&
            <div> 
            <p className="text-sm ">Derwent Street Dental Practice
              
            </p>
            <p className="font-light text-sm py-1"> 14 Derwent Street, Consett, DH8 8LU  </p>

           
            <p className="text-sm flex item-center gap-1 font-light"> <svg class="w-5 h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"/>
            </svg>01207503079</p>
            </div>
          }
        </div>
        }
        </div>

        

        <div className="hidden md:block">
          {
            selectedOption === 'Rosebrough Dental Practice' && 
            <div className="flex gap-1 items-center">
            <svg
            class="w-4 h-4 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 21"
          >
            <g
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path d="M8 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              <path d="M13.8 12.938h-.01a7 7 0 1 0-11.465.144h-.016l.141.17c.1.128.2.252.3.372L8 20l5.13-6.248c.193-.209.373-.429.54-.66l.13-.154Z" />
            </g>
          </svg><p className="text-sm text-white">Rosebrough Dental Practice - <span className="font-light">Four Lane Ends Benton NE7 7UH  </span></p>
          <svg class="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"/>
</svg><p className="text-sm text-white font-light">01912662095</p>

          </div>
          }
          {
            selectedOption === 'Derwent Street Dental Practice' && 
            <div className="flex gap-1 items-center">
            <svg
            class="w-4 h-4 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 21"
          >
            <g
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path d="M8 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              <path d="M13.8 12.938h-.01a7 7 0 1 0-11.465.144h-.016l.141.17c.1.128.2.252.3.372L8 20l5.13-6.248c.193-.209.373-.429.54-.66l.13-.154Z" />
            </g>
          </svg><p className="text-sm text-white">Derwent Street Dental Practice - <span className="font-light">14 Derwent Street Consett, DH8 8LU </span></p>
          <svg class="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"/>
</svg><p className="text-sm text-white font-light">01207503079</p>

          </div>
          }

          {/* <p className="text-xs sm:text-base text-white">
            Call Us Today - 0191 266 2095{" "}
          </p> */}
        </div>
      </div>
      {/* topbar ends */}

      {/* common navbar starts */}
      <div
        className={
          location.pathname === "/dental-implants/for-dentist"
            ? "flex w-full justify-between items-center h-[120px] sm:pt-10 pt-12 px-4  pl-6 z-10 text-white bg-secondory"
            : "flex w-full justify-between items-center h-20 px-4 py-2 md:top-10 top-11 pl-6 absolute z-10 text-white"
        }
      >
        <div className="z-[60]">
          <Link to="/">
            {/* <h1
              className={
                nav
                  ? "cursor-pointer px-2 text-2xl bg-black w-fit py-3"
                  : "cursor-pointer px-2 text-2xl bg-black w-fit py-3"
              }
            >
              CogniSmiles.
            </h1> */}
            <img src={logo} alt="logo" width={90} />
          </Link>
        </div>
        <ul className="hidden flex-grow justify-center min-[1200px]:flex md:gap-2 lg:gap-5">
          {
            menu?.map((data) => {
              if (data.attributes.title === "Book Appointments") {
                return null;
              }
            return (
              
                data?.attributes?.children?.data.length <= 0 ?
                <li
                  className=
                  {
                  path
                    ? "text-white md:text-sm lg:text-base transition-all hover:border-b"
                    : "text-primary transition-all md:text-sm hover:border-b hover:border-primary lg:text-base"
                  }
                id={data.id}
                >
                <Link to={data.attributes.url}>{data.attributes.title}</Link> 
                </li> :
             
                  <li className=
                  {
                  path
                    ? "text-white md:text-sm lg:text-base transition-all group relative"
                    : "text-primary transition-all md:text-sm lg:text-base group relative"
                  }>{data.attributes.title}
                  <div className="bg-white space-y-2 rounded-md shadow-md absolute w-28 -left-[35%] z-99 px-2 text-left py-3 hidden group-hover:block">
                    <svg class="w-6 h-6 text-white absolute left-[40%] -top-[10px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path fill-rule="evenodd" d="M5.575 13.729C4.501 15.033 5.43 17 7.12 17h9.762c1.69 0 2.618-1.967 1.544-3.271l-4.881-5.927a2 2 0 0 0-3.088 0l-4.88 5.927Z" clip-rule="evenodd"/>
                    </svg>
                    {
                      data?.attributes?.children?.data?.map((dropdownItem) => {
                      return (
                        <li>
                          <Link
                            key={dropdownItem?.id}
                            className="text-black hover:underline"
                            to={dropdownItem?.attributes?.url}
                          >
                          {dropdownItem?.attributes?.title}
                          </Link>
                        </li>
                      );
                      })
                    }
                  </div>
                  </li>
                  
           
                 
            )
          })
          }
        </ul>
        <div className="hidden min-[1200px]:flex gap-3">
        <Link to="/referrals"> <button
            className={
              path
                ? "border-white border-[1px] p-2 rounded-md hover:scale-105 transition-all"
                : "border-primary border-[1px] p-2 rounded-md text-primary hover:scale-105 transition-all"
            }
          >
            Referrals
          </button></Link>
          <Link to="/our-courses"><button className="bg-tertiary border-tertiary border-[1px] text-white p-2 rounded-md hover:scale-105 transition-all">
            Courses For Dentists
          </button></Link>
        </div>
        {/* hamburger icon start */}
        <div className="min-[1200px]:hidden z-50" onClick={handleNav}>
          {nav ? (
            <svg
              className="w-10 h-10 bg-primary p-3 rounded-md text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          ) : (
            <svg
              className="w-10 h-10 bg-primary p-3 rounded-md text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          )}
        </div>
        {/* hamburger icon end */}
        {/* mobile menu start */}
        <div
          className={
            nav
              ? "absolute min-[1200px]:hidden left-0 top-0 bg-white shadow-md w-full px-4 py-5 z-30 flex flex-col"
              : "absolute left-[-100%]"
          }
        >
          <ul className="mt-14">
            {/* <h1 className="px-2 text-2xl cursor-pointer bg-black w-fit text-white py-3 -mt-2">
              CogniSmiles.
            </h1> */}
            {menu?.map((data) => {
                if (data.attributes.title === "Book Appointments") {
                  return null;
                }
              return (
                data?.attributes?.children?.data.length <= 0 ?
                <li
                  onClick={() => setNav(false)}
                  className="text-xl text-center border-b-[0.3px] p-3 mt-1 text-primary"
                  id={data.id}
                >
                  <Link to={data.attributes.url}>{data.attributes.title}</Link>
                </li> : 
                <li 
                className="text-xl text-center border-b-[0.3px] p-3 mt-1 text-primary"
                onClick={() => setShow(!show)}
                >
                  <div className="flex items-center justify-center gap-2">{data.attributes.title} <svg className="w-6 h-6 text-primary mt-[3px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M18.425 10.271C19.499 8.967 18.57 7 16.88 7H7.12c-1.69 0-2.618 1.967-1.544 3.271l4.881 5.927a2 2 0 0 0 3.088 0l4.88-5.927Z" clip-rule="evenodd"/>
</svg></div>
                  

                  <div className={`${show ? 'block' : 'hidden'} mt-3 space-y-3`}>
                    {
                      data?.attributes?.children?.data?.map((Item) => {
                        return (
                          <li
                          onClick={() => setNav(false)} 
                          className="text-xl text-center text-primary font-light hover:underline"
                          >
                            <Link

                              key={Item?.id}
                              className=""
                              to={Item?.attributes?.url}
                            >
                            {Item?.attributes?.title}
                            </Link>
                          </li>
                        );
                        })
                    }
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="flex gap-5 flex-col sm:flex-row w-full gap-y-2 my-5">
            <Link className="w-full" to="/referrals"><button onClick={() => setNav(false)} className=" w-full  border-primary border-[1px] p-2 rounded-md text-primary shadow-md">
            Referrals
            </button></Link>
            <Link className="w-full" to="/our-courses"><button  onClick={() => setNav(false)} className="w-full  bg-tertiary border-tertiary border-[1px] text-white p-2 rounded-md  shadow-md">
            Courses For Dentists
            </button></Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
